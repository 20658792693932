<script>
  export default {
    inheritAttrs: false
  }
</script>

<script setup>
  const props = defineProps(['as'])
</script>

<template>
  <component v-if="props.as" v-bind="$attrs" :is="props.as">
    <slot />
  </component>
  <slot v-else />
</template>
